import {
  PRO_PREMIUM_MONITORING,
  PRO_PREMIUM_MONITORING_UK
} from '@ecomm/data-constants'
import { useLocale } from '@ecomm/data-hooks'
import { Modal } from '@ecomm/shared-components'
import { useLockBodyScroll } from '@ecomm/shared-hooks'
import {
  useTrackOdmonReturnToCart,
  useTrackOdmonSkipAnyway,
  useTrackOdmonSkipThisStep,
  useTrackOdmonTermsServiceClick
} from '@ecomm/tracking'
import className from 'classnames'
import { navigate } from 'gatsby'
import type React from 'react'
import { useState } from 'react'

import { AddPlanToCartButtonProduct } from '../AddPlanToCartButton'
import type {
  BottomTextSectionSchema,
  LinkTextElement,
  ModalButtonTypes,
  SimpleTextElement,
  TrackingTypes
} from './schema'

interface Props {
  readonly textElements: BottomTextSectionSchema
}

interface ModalButtonProps {
  readonly type: ModalButtonTypes
  readonly tracking?: TrackingTypes
  readonly url?: string
}

function BottomTextSection({ textElements }: Props) {
  const trackTermsService = useTrackOdmonTermsServiceClick()
  const trackReturnToCart = useTrackOdmonReturnToCart()
  const trackSkipStep = useTrackOdmonSkipThisStep()
  const trackSkipAnyway = useTrackOdmonSkipAnyway()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const locale = useLocale()
  const { content, modal } = textElements
  const trackingFns = {
    'terms-of-service': () => trackTermsService('page'),
    'return-to-cart': () => trackReturnToCart(),
    'skip-step': () => trackSkipStep(),
    'skip-anyway': () => trackSkipAnyway()
  }
  const triggerTracking = (tracking?: TrackingTypes) => {
    tracking && trackingFns[tracking] && trackingFns[tracking]()
  }

  const handleLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    tracking: TrackingTypes,
    openModalOnClick = false
  ) => {
    if (modal && openModalOnClick) {
      e.preventDefault()
      setIsOpenModal(true)
    }
    triggerTracking(tracking)
  }

  const newTabUrls = ['/terms-of-service']

  const renderText = (
    element: LinkTextElement | SimpleTextElement,
    index: number
  ) => {
    if (element.type === 'link') {
      return (
        <a
          href={element.to}
          key={index}
          onClick={e =>
            handleLinkClick(e, element.tracking, element.openModalOnClick)
          }
          rel="noreferrer"
          target={newTabUrls.includes(element.to) ? '_blank' : '_self'}
        >
          {element.text}
        </a>
      )
    } else {
      const renderMethods = {
        bold: (element: SimpleTextElement) => (
          <span className="font-medium" key={index}>
            {element.text}
          </span>
        ),
        text: (element: SimpleTextElement) => (
          <span key={index}>{element.text}</span>
        )
      }
      return renderMethods[element.type](element)
    }
  }

  const handleModalButtonClick = ({
    type,
    tracking,
    url
  }: ModalButtonProps) => {
    if (type === 'secondary') {
      triggerTracking(tracking)
      url && navigate(url)
    }

    setIsOpenModal(false)
  }

  useLockBodyScroll(isOpenModal, false)

  return (
    <div className="text-center">
      {content.map((paragraph, index) => (
        <p key={index}>{paragraph.map((item, i) => renderText(item, i))}</p>
      ))}
      {modal ? (
        <Modal
          isOpen={isOpenModal}
          onRequestClose={() => setIsOpenModal(false)}
          size="small"
        >
          <div className="p-8">
            <h3 className="mb-3 mt-0 text-3xl	font-medium md:text-4xl">
              {modal.title}
            </h3>
            <p className="m-0 mb-4 text-base md:text-lg">{modal.description}</p>
            <div className="mt-8 flex flex-col items-center justify-around text-center md:flex-row">
              {modal.buttons.map((button, index) => {
                return button.action &&
                  button.action === 'AddProPremiumToCart' ? (
                  <AddPlanToCartButtonProduct
                    buttonText={button.text}
                    className="h-16 w-full !px-4 md:w-64"
                    sku={
                      locale === 'en-GB'
                        ? PRO_PREMIUM_MONITORING_UK
                        : PRO_PREMIUM_MONITORING
                    }
                  />
                ) : (
                  <button
                    className={className('w-full md:w-auto', {
                      'btn btn-solid-primary': button.type === 'primary',
                      'btn btn-outlined btn-outlined-tertiary !text-neutral-black mt-2 text-lg hover:underline md:mt-0':
                        button.type === 'secondary'
                    })}
                    key={index}
                    onClick={() =>
                      handleModalButtonClick({
                        type: button.type,
                        tracking: button.tracking,
                        url: button.to
                      })
                    }
                  >
                    {button.text}
                  </button>
                )
              })}
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  )
}

export { BottomTextSection }
