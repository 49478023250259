import { useMicroCopy } from '@ecomm/micro-copy'
import { GatsbyImage } from '@ecomm/shared-components'
import { useTrackingCardShopDetails } from '@ecomm/tracking'
import { Link } from 'gatsby'

import type { ShopProductSchema } from '../../templates/Shop/shopPageSchema'

export default function ShopAccessoryCard({
  name,
  image,
  isNew,
  slug
}: ShopProductSchema) {
  const microcopy = useMicroCopy()
  const trackSeeDetails = useTrackingCardShopDetails(name)

  return (
    <li className="rounded-base bg-neutral-light-100 col-span-12 flex flex-col justify-between px-5 py-8 text-center md:col-span-6 md:py-12 lg:col-span-3">
      <h3 className="mb-6 text-2xl font-light">{name}</h3>
      <div className="relative w-full">
        {image ? (
          <GatsbyImage
            className="h-40 md:h-52"
            image={image}
            objectFit="contain"
          />
        ) : null}
        {isNew ? (
          <span className="bg-primary-100 absolute left-1/2 top-[17%] translate-x-5 rounded-md px-2 py-1 text-center text-xs font-medium md:text-lg">
            {microcopy['new']}
          </span>
        ) : null}
      </div>
      <Link
        className="btn btn-outlined btn-outlined-primary !px-4 mx-auto mt-6 block min-w-[190px] md:w-auto"
        onClick={trackSeeDetails}
        to={`/${slug}`}
      >
        {microcopy['see-details']}
      </Link>
    </li>
  )
}
