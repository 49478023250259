import { LeadGenResponseProps, leadGenCapture } from '@ecomm/data-leads'
import { useOptimizelyTrack } from '@ecomm/optimizely-hooks'
import {
  COOKIE_LEAD_DATA,
  getLeadData,
  getValueFromLeadData,
  updateLeadData,
  useCookieChange
} from '@ecomm/shared-cookies'
import {
  ReferrerUrlContext,
  brazeTrackPromoView,
  handleBrazeTrackingEvent,
  trackLeadCapture
} from '@ecomm/tracking'
import { LeadGenCaptureResponse } from '@simplisafe/ss-ecomm-data/simplisafe'
import * as E from 'fp-ts/lib/Either'
import { useContext, useState } from 'react'
import { useTracking } from 'react-tracking'

import { useBannerInteractionState } from '../useBannerInteractionState'
import { useGetActivePromoCode } from '../useGetActivePromoCode'
import { useGetPromoLeadSource } from '../useGetPromoLeadSource'
import { useLeadCaptureEmailSubmitted } from '../useLeadCaptureEmailSubmitted'

export type LeadCaptureFormPropsOnSubmit = (
  _email: string,
  _onFailure: (_errorText: string) => void
) => void

export type LeadCaptureFormPropsOnPhoneNumberSubmit = (_phone: string) => void

export const useHandleFormSubmission = () => {
  const trackLeadCaptureFailure = useOptimizelyTrack({
    eventKey: 'website_error'
  })
  const trackLeadCaptureSuccess = useOptimizelyTrack({
    eventKey: 'lead_captured_fs'
  })
  const { trackEvent } = useTracking({ appSection: 'promoSubmit' })

  const [defaultEmail, setDefaultEmail] = useState<string>(
    getLeadData()?.['email'] || ''
  )

  const [defaultPhoneNumber, setDefaultPhoneNumber] = useState<string>(
    getLeadData()?.['phone'] || ''
  )

  useCookieChange(COOKIE_LEAD_DATA, data => {
    const email = JSON.parse(data)?.email || ''
    setDefaultEmail(email)

    const phone = JSON.parse(data)?.phone || ''
    setDefaultPhoneNumber(phone)
  })

  const channel = useContext(ReferrerUrlContext).channel

  const promoCode = useGetActivePromoCode()
  const source = useGetPromoLeadSource()

  const { onCloseBanner } = useBannerInteractionState()
  const { isSubmitted, setIsSubmitted } = useLeadCaptureEmailSubmitted()

  const handleEmailSubmit: LeadCaptureFormPropsOnSubmit = (
    email,
    onFailure
  ) => {
    const handleLeadCaptureFailure = () => {
      trackLeadCaptureFailure()
      onFailure("We've encountered an error. Please try again later.")
    }

    const handleLeadCaptureSuccess = async (value: LeadGenResponseProps) => {
      setIsSubmitted(true)
      onCloseBanner()
      brazeTrackPromoView()
      trackLeadCapture(value, trackEvent, trackLeadCaptureSuccess, channel)
      console.info(
        `In promotions useHandleFormSubmission called trackLeadCapture with ${JSON.stringify(
          { leadId: value.leadId, externalId: value.externalId }
        )}`
      )
    }

    leadGenCapture({
      email,
      promoCode,
      source
    })()
      .then(E.match(handleLeadCaptureFailure, handleLeadCaptureSuccess))
      .catch(handleLeadCaptureFailure)
  }

  // For Phone Number Capture Experiment (ECP-10762)
  const handlePhoneNumberSubmit: LeadCaptureFormPropsOnPhoneNumberSubmit =
    async phone => {
      const leadDataCapture: LeadGenCaptureResponse = {
        externalId: getValueFromLeadData('externalId') || '',
        leadSource: source
      }
      await updateLeadData({ phone: phone })
      await handleBrazeTrackingEvent(leadDataCapture, channel)
      brazeTrackPromoView()
      setIsSubmitted(true)
      onCloseBanner()
    }

  return {
    defaultEmail,
    defaultPhoneNumber,
    isFormSubmitted: isSubmitted,
    handleEmailSubmit,
    handlePhoneNumberSubmit
  }
}
