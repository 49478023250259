import * as IO from 'fp-ts/lib/IO'
import * as O from 'fp-ts/lib/Option'
import * as TE from 'fp-ts/lib/TaskEither'
import { pipe } from 'fp-ts/lib/function'

export const baseApiUrl = pipe(
  process.env['BASE_SS_API_URL'] ?? process.env['GATSBY_BASE_SS_API_URL'],
  TE.fromNullable(Error('[ENV]: BASE_SS_API_URL not defined'))
)

export const yodaApiUrl = pipe(
  process.env['YODA_API_URL'],
  TE.fromNullable(Error('[ENV]: YODA_API_URL not defined'))
)

type Locale = 'en-GB' | 'en-US'

const isLocale = (str: Locale | string): str is Locale =>
  ['en-US', 'en-GB'].includes(str)

/**
 * `LOCALE`
 *
 * @example
 * import { locale } from '@ecomm/data-env-variables`
 *
 * const showLocale = async () => {
 *    console.log(await locale())
 * }
 */
export const locale = pipe(
  TE.of(process.env['LOCALE'] ?? process.env['GATSBY_LOCALE']),
  TE.chain(TE.fromNullable(Error('[ENV]: LOCALE not defined'))),
  TE.chain(l => {
    return isLocale(l)
      ? TE.of(l)
      : TE.left(Error(`[env]: LOCALE is set to an invalid value: ${l}`))
  })
)

type DeployEnv = 'development' | 'prd' | 'qa' | 'stg'

const isDeployEnv = (str: DeployEnv | string): str is DeployEnv =>
  ['development', 'prd', 'qa', 'stg'].includes(str)

export const deployEnv: TE.TaskEither<Error, DeployEnv> = pipe(
  TE.of(process.env['DEPLOY_ENV'] ?? process.env['GATSBY_DEPLOY']),
  TE.chain(TE.fromNullable(Error('[ENV]: DEPLOY_ENV not defined'))),
  TE.chain(deploy =>
    isDeployEnv(deploy)
      ? TE.of(deploy)
      : TE.left(
          Error(
            `[env]: DEPLOY_ENV is set to an invalid value: ${process.env['DEPLOY_ENV']}`
          )
        )
  )
)

export const baseProductsUrl = IO.of(process.env['GATSBY_BASE_PRODUCTS_URL'])

export const baseCartsUrl = IO.of(process.env['GATSBY_BASE_CARTS_URL'])

export const perimeterXEnabled = IO.of(
  process.env['GATSBY_PX_CAPTCHA_ENABLED'] ?? process.env['PX_CAPTCHA_ENABLED']
)

export const gatsbyDevMode = IO.of(process.env['GATSBY_DEV_MODE'])

export const nodeEnv = IO.of(process.env['NODE_ENV'])

export const baseOdmonApiUrl = IO.of(process.env['GATSBY_BASE_ODMON_API_URL'])

export const baseOdmonUrl = IO.of(process.env['GATSBY_BASE_ODMON_URL'])

export const webAppUrl = () =>
  pipe(
    O.fromNullable(process.env['WEBAPP_URL']),
    O.getOrElse(() => 'https://webapp.simplisafe.com')
  )
