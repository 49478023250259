import {
  type Options,
  documentToReactComponents
} from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { Link, PreloadLink } from '@ecomm/framework'
import { type LegalTextPage, LEGAL_TEMPLATE_QUERY } from '@ecomm/legal-data'
import { leadingSlashIt } from '@simplisafe/ewok'
import React from 'react'

const legalRenderers = (links: LegalTextPage['text']['links']): Options => {
  const entries = new Map()

  links?.entries?.hyperlink?.forEach(link => entries.set(link.sys.id, link))
  links?.entries?.inline?.forEach(link => entries.set(link.sys.id, link))

  return {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: node => {
        const targetId = node.data['target']['sys']['id']
        const entry = entries.get(targetId)
        const type = entry?.__typename

        return type === 'Link' ? (
          <button className="ot-sdk-show-settings text-body-size cursor-pointer border-0 bg-transparent p-0 text-base underline">
            {entry.text}
          </button>
        ) : type === 'JumpLink' ? (
          <p className="m-0" id={entry?.identifier} />
        ) : null
      },
      [INLINES.HYPERLINK]: node => {
        const type = node.content[0]?.nodeType
        return type === 'text' ? (
          <Link to={`${node.data?.['uri']}`}>{node.content[0]?.value}</Link>
        ) : null
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const targetId = node.data['target'].sys.id
        const entry = entries.get(targetId)

        return (
          <PreloadLink
            queries={[
              {
                query: LEGAL_TEMPLATE_QUERY,
                variables: {
                  slug: entry?.slug
                }
              }
            ]}
            to={leadingSlashIt(entry?.slug)}
          >
            {children}
          </PreloadLink>
        )
      },
      [BLOCKS.TABLE_CELL]: (_, children) => {
        return (
          <td className="border-neutral-medium border-b-0 border-l-0 border-r border-t-0 border-solid p-5 first:col-span-2 last:border-r-0">
            {children}
          </td>
        )
      },
      [BLOCKS.TABLE]: (_, children) => {
        return (
          <table
            className="rounded-base bg-neutral-light-50 prose-p:mb-0 w-full overflow-hidden text-center"
            style={{ borderCollapse: 'collapse' }}
          >
            <tbody>{children}</tbody>
          </table>
        )
      },
      [BLOCKS.TABLE_HEADER_CELL]: (_, children) => {
        return (
          <th className="border-neutral-medium border-b-0 border-l-0 border-r border-t-0 border-solid p-5 first:col-span-2 last:border-r-0">
            {children}
          </th>
        )
      },
      [BLOCKS.TABLE_ROW]: (_, children) => {
        return (
          <tr className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid last:border-b-0">
            {children}
          </tr>
        )
      }
    }
  }
}

export const LegalRichText = React.memo(
  ({ text }: { readonly text: LegalTextPage['text'] }) => (
    <span data-testid="legal-rich-text">
      {documentToReactComponents(text.json, { ...legalRenderers(text.links) })}
    </span>
  )
)
