import { useLocale } from '@ecomm/data-hooks'
import { Footer } from '@ecomm/footer-components'
import { useFooterQuery } from '@ecomm/footer-data'
import { Breadcrumbs, Header } from '@ecomm/header-redesigned'
import {
  useCameraBannerAssetQuery,
  useGuaranteeAssetQuery,
  useGuaranteeCopyQuery
} from '@ecomm/lander'
import { LiveChat } from '@ecomm/live-chat'
import { useMicroCopy } from '@ecomm/micro-copy'
import {
  type VideoBannerProps,
  VideoBanner
} from '@ecomm/monitoring/components'
import {
  ApplyPromoCode,
  PageToaster,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import {
  type ComparisonTableProps,
  CameraBanner,
  ComparisonTable,
  GuaranteeSection,
  safeParseJSONDataFromContentful,
  transformToComparisonTableData
} from '@ecomm/scout'
import {
  type HeroBannerSchema,
  AffirmBanner,
  HeroBanner,
  toPromoBannerStyleValue,
  TrustpilotUKTopBanner
} from '@ecomm/shared-components'
import {
  Experience,
  mapExperiences,
  useOdmonExperience
} from '@ecomm/shared-ninetailed'
import { ColoredSection } from '@ecomm/shared-sections'
import { TrackingProvider } from '@ecomm/tracking'
import { SEO } from '@ecomm/utils'
import classNames from 'classnames'
import * as O from 'fp-ts/lib/Option'
import { type PageProps, graphql } from 'gatsby'
import { Suspense } from 'react'

import ShopAccesoryCardList from '../../components/ShopAccessoryCardList'
import ShopImageLinkList from '../../components/ShopImageLinkList'
import type { SeoNodeSchema } from '../../config/seoNodeSchema'
import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { useHeaderRedesignQuery } from '../../hooks/HeaderRedesign/useHeaderRedesignQuery'
import { useShopPageFragment } from './useShopPageFragment'
import { useShopPageQuery } from './useShopPageQuery'

export type PageContext = {
  readonly locale: string
  readonly seoDetails: SeoNodeSchema
  readonly slug: string
}

type Props = Partial<PageProps> & {
  readonly data: { readonly contentfulShopPage: unknown }
  readonly pageContext: PageContext
}

function Content({ data, pageContext: { locale, seoDetails } }: Props) {
  const {
    layout,
    products,
    heroBanner,
    links,
    slug,
    breadcrumbTitle,
    mainTitle
  } = useShopPageFragment(data)
  const {
    promoBannerStyle,
    liveChat,
    footer: { contentful_id: footerId },
    components
  } = layout
  const footer = useFooterQuery(footerId)
  const headerData = useHeaderRedesignQuery()
  const microcopy = useMicroCopy()
  const componentsData = useShopPageQuery()
  const guaranteeSectionCopy = useGuaranteeCopyQuery()
  const isOdmonVariant = useOdmonExperience().isVariant
  const guaranteeAsset = useGuaranteeAssetQuery()
  const isUs = useLocale() === 'en-US'
  const cameraBannerAsset = useCameraBannerAssetQuery()

  const comparisonTableData: ComparisonTableProps = isOdmonVariant
    ? transformToComparisonTableData(
        componentsData,
        'comparisonTableOdmonVariant'
      )
    : transformToComparisonTableData(componentsData)
  const promoType = toPromoBannerStyleValue(promoBannerStyle) ?? 'none'

  const {
    metaTitle,
    metaDescription,
    metaKeywords,
    isNofollow,
    isNoindex,
    canonicalLink
  } = seoDetails

  const coloredSection = components?.[0]

  const promoBannerExperiment = usePromoBannerExperimentQuery()
  const videoBannerData: O.Option<VideoBannerProps> =
    safeParseJSONDataFromContentful(componentsData, 'videoBanner')

  return (
    <TrackingProvider metaTitle="shop">
      <PageToaster />
      <ApplyPromoCode />
      <div className="prose md:prose-md lg:prose-lg whitespace-pre-line">
        <SEO
          canonicalLink={canonicalLink ?? ''}
          isNofollow={isNofollow}
          isNoindex={isNoindex}
          lang={locale}
          metaDescription={metaDescription.metaDescription}
          metaKeywords={metaKeywords ?? []}
          metaTitle={metaTitle}
        />
        <PromoBannerWrapper
          experimentData={promoBannerExperiment}
          type={promoType}
        />
        <Header {...headerData} />
        {breadcrumbTitle ? (
          <div className="-ml-3 md:ml-0 lg:mt-4">
            <Breadcrumbs
              steps={[
                {
                  label: breadcrumbTitle,
                  slug
                }
              ]}
            />
          </div>
        ) : null}
        {mainTitle ? (
          <h1 className="text-center mt-6 md:mt-4 mb-5 lg:mb-10 text-4xl md:text-5xl lg:text-heading-1-size">
            {mainTitle}
          </h1>
        ) : null}
        {heroBanner ? (
          isUs ? (
            <Experience
              {...{ ...heroBanner, heightType: 'any' }}
              component={HeroBanner}
              experiences={mapExperiences<HeroBannerSchema>(
                heroBanner.nt_experiences
              )}
              id={heroBanner.id}
              key={heroBanner.id}
              passthroughProps={{
                className: 'prose-h2:mb-5 lg:h-[600px]'
              }}
            />
          ) : (
            <HeroBanner
              className="prose-h2:mb-5 lg:h-[600px]"
              {...heroBanner}
              heightType="any"
            />
          )
        ) : null}
        <main className="max-w-8xl mx-auto my-8 grid grid-cols-1 gap-8 px-4 md:px-8 lg:my-10 lg:gap-12">
          {locale === 'en-GB' && <TrustpilotUKTopBanner className="order-1" />}
          {locale === 'en-US' ? (
            <AffirmBanner
              className="order-1 w-full"
              data={{
                __typename: 'ContentfulAffirmBanner',
                title: microcopy['affirm-buy-now-title']
              }}
            />
          ) : null}
          <ShopImageLinkList className="order-3" links={links} />
          <ShopAccesoryCardList
            className={classNames({
              'order-2': locale === 'en-GB',
              'order-3': locale === 'en-US'
            })}
            products={products}
          />
          {coloredSection ? (
            <section className="order-last">
              <ColoredSection {...coloredSection} />
            </section>
          ) : null}
          {locale === 'en-US' && (
            <div className="order-5 grid gap-8 lg:gap-12">
              {isOdmonVariant && O.isSome(videoBannerData) ? (
                <VideoBanner data={videoBannerData.value} />
              ) : (
                <CameraBanner
                  image={cameraBannerAsset}
                  title="Stop crime in real time"
                />
              )}
              <ComparisonTable data={comparisonTableData} />
              <GuaranteeSection
                copy={guaranteeSectionCopy}
                image={guaranteeAsset.guaranteeAsset}
                quoteWizard={componentsData.quoteWizard}
              />
            </div>
          )}
        </main>
        <Footer {...footer} type="Full" />
        {liveChat ? <LiveChat /> : null}
      </div>
    </TrackingProvider>
  )
}

export default function ShopTemplate(props: Props) {
  return (
    <Suspense>
      <Content {...props} />
    </Suspense>
  )
}

export const shopTemplateQuery = graphql`
  #graphql
  query ShopTemplate($id: String) {
    contentfulShopPage(id: { eq: $id }) {
      ...shopPage
    }
  }
`
