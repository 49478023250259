import { BlogPreview } from '@ecomm/blog-components'
import { BLOG_PREVIEW_LIST_QUERY, blogListSchema } from '@ecomm/blog-data'
import { Footer } from '@ecomm/footer-components'
import { useFooterQuery } from '@ecomm/footer-data'
import { Breadcrumbs, Header } from '@ecomm/header-redesigned'
import { useMicroCopy } from '@ecomm/micro-copy'
import {
  ApplyPromoCode,
  PageToaster,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import { useContentful } from '@ecomm/shared-apollo'
import {
  toPromoBannerStyleValue,
  TrustpilotUKTopBanner
} from '@ecomm/shared-components'
import { TrackingProvider } from '@ecomm/tracking'
import { SEO } from '@ecomm/utils'
import type { PageProps } from 'gatsby'

import UKBlogHeroBanner from '../../components/UKBlogHeroBanner'
import type { SeoNodeSchema } from '../../config/blogPageResponseNodeSchema'
import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { useBlogLayout } from '../../hooks/useBlogLayout'
import { useHeaderRedesignQuery } from '../../hooks/useHeaderRedesignQuery'

export type PageContext = {
  readonly locale: string
  readonly seoDetails: SeoNodeSchema
  readonly buildTime: string
}

type Props = Partial<PageProps> & {
  readonly pageContext: PageContext
}

export default function BlogListTemplate({
  pageContext: { locale, seoDetails, buildTime }
}: Props) {
  const microCopy = useMicroCopy()

  const { promoBannerStyle, footer } = useBlogLayout()
  const footerId =
    footer?.id || locale === 'en-US'
      ? '2SCKVa13yRuLSU4GuooHlb'
      : '5m1zxp97jhi0O96SH5dwGQ'
  const footerData = useFooterQuery(footerId)

  const { data: apolloData } = useContentful(BLOG_PREVIEW_LIST_QUERY, {
    site: locale === 'en-US' ? 'US' : 'UK',
    /**
     * The date variable is used to prevent the page from showing entries that have
     * been created since the last build. We don't want Gatsby to show links to posts that
     * don't have pages built for the articles yet.
     *
     * We can safely delete this variable once we are fully on Remix.
     */

    date: process.env['PUBLIC_CONTENTFUL_PREVIEW'] === 'true' ? null : buildTime
  })

  const items = blogListSchema.parse(apolloData?.blogCollection?.items, {
    path: ['Blog List Template', 'blogListSchema']
  })

  const bannerStyle = toPromoBannerStyleValue(promoBannerStyle) || 'none'

  const {
    metaTitle,
    metaDescription,
    metaKeywords,
    isNofollow,
    isNoindex,
    canonicalLink
  } = seoDetails

  const headerData = useHeaderRedesignQuery()
  const promoBannerExperiment = usePromoBannerExperimentQuery()

  return (
    <TrackingProvider metaTitle={metaTitle}>
      <PageToaster />
      <ApplyPromoCode />
      <SEO
        canonicalLink={canonicalLink ?? ''}
        isNofollow={isNofollow}
        isNoindex={isNoindex}
        lang={locale}
        metaDescription={metaDescription?.metaDescription ?? ''}
        metaKeywords={metaKeywords ?? []}
        metaTitle={metaTitle}
      />
      <PromoBannerWrapper
        experimentData={promoBannerExperiment}
        type={bannerStyle}
      />
      <Header {...headerData} />
      <Breadcrumbs
        steps={[
          {
            label: 'Blog',
            slug: 'blog'
          }
        ]}
      />
      <div className="prose md:prose-md lg:prose-lg whitespace-pre-line">
        {locale === 'en-GB' && (
          <>
            <TrustpilotUKTopBanner className="mt-7 md:mt-8"></TrustpilotUKTopBanner>
            <UKBlogHeroBanner title={microCopy['blog-hero-title']} />
          </>
        )}
        <ul className="m-0 mx-auto grid max-w-4xl list-none gap-4 px-4 py-4 md:gap-8 md:px-8 md:pb-16 md:pt-10 lg:gap-16 lg:px-0">
          {items.map((post, index) => (
            <BlogPreview key={`post-${index}`} {...post} index={index} />
          ))}
        </ul>
      </div>
      {footer ? <Footer {...footerData} type="Full" /> : null}
    </TrackingProvider>
  )
}
