import { PriceProvider } from '@ecomm/data-price'
import { Footer } from '@ecomm/footer-components'
import { useFooterQuery } from '@ecomm/footer-data'
import { Breadcrumbs, Header } from '@ecomm/header-redesigned'
import { LiveChat } from '@ecomm/live-chat'
import {
  ApplyPromoCode,
  PageToaster,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import {
  toPromoBannerStyleValue,
  TrustpilotUKTopBanner
} from '@ecomm/shared-components'
import { PageWrapper } from '@ecomm/shared-components'
import { TrackingProvider } from '@ecomm/tracking'
import { type Locale, SEO } from '@ecomm/utils'
import { useLocation } from '@reach/router'
import { type PageProps, graphql } from 'gatsby'
import { Suspense, useEffect, useState } from 'react'
import { useOdmonExperience } from '@ecomm/shared-ninetailed-odmon'
import { OLYMPUS_SKU, SHIELD_SKU } from '@ecomm/data-constants'
import { head, lookup } from 'fp-ts/lib/Array'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

import AlarmTile from '../../components/AlarmTile'
import AuthenticationComponent from '../../components/Authentication'
import { AuthenticationType } from '../../components/Authentication/types'
import Banner from '../../components/BannerProducts'
import DiscountBanner from '../../components/DiscountBanner'
import ProductCards from '../../components/ProductCards'
import type { SeoNodeSchema } from '../../config/seoNodeSchema'
import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { useResetDraftCart } from '../../hooks/draftCart/useResetDraftCart'
import { useHeaderRedesignQuery } from '../../hooks/HeaderRedesign/useHeaderRedesignQuery'
import { useSensorAlarmPageFragment } from './useAlarmSensorPageFragment'
import type { GroupSchema } from './alarmSensorPageSchema'

export type PageContext = {
  readonly locale: Locale
  readonly seoDetails: SeoNodeSchema
}

const BANNER_TYPE = 'SS2 Upgrade'

type Props = Partial<PageProps> & {
  readonly data: { readonly contentfulAlarmSensorPage: unknown }
  readonly pageContext: PageContext
}

export type Selected = {
  readonly index: number
  readonly showProducts: boolean
}

function Content({ data, pageContext: { locale, seoDetails } }: Props) {
  const { groups, layout, breadcrumbTitle, slug } =
    useSensorAlarmPageFragment(data)
  const location = useLocation()
  const {
    metaTitle,
    metaDescription,
    metaKeywords,
    isNofollow,
    isNoindex,
    canonicalLink
  } = seoDetails

  const {
    footer: { contentful_id: footerId },
    liveChat
  } = layout
  const bannerStyle = toPromoBannerStyleValue(layout.promoBannerStyle) || 'none'

  const footer = useFooterQuery(footerId)

  const [selected, setSelected] = useState<Selected>({
    index: 0,
    showProducts: groups.length === 1
  })

  const onSelect = (_selected: Selected) => {
    setSelected(_selected)
  }

  const isOdmonVariant = useOdmonExperience().isVariant

  const hideOlympusCamera = (group: GroupSchema) => {
    const productsWithoutOlympus = group.products.filter(
      product => product.sku !== OLYMPUS_SKU
    )
    return { ...group, products: productsWithoutOlympus }
  }

  const orderProducts = (group: GroupSchema) => {
    const shieldCamera = group.products.find(
      product => product.sku === SHIELD_SKU
    )
    const olympusCamera = group.products.find(
      product => product.sku === OLYMPUS_SKU
    )
    const productsWithoutOutdoorCameras = group.products.filter(
      product => product.sku !== SHIELD_SKU && product.sku !== OLYMPUS_SKU
    )

    // Filter out any undefined values from the product array
    const products = [
      shieldCamera,
      olympusCamera,
      ...productsWithoutOutdoorCameras
    ].filter(product => !!product)

    return {
      ...group,
      products
    }
  }

  const createUpdatedGroups = (ss3Group: GroupSchema) => {
    return pipe(
      lookup(1, groups),
      O.fold(
        () => [ss3Group],
        group => [ss3Group, group]
      )
    )
  }

  const firstGroup = pipe(
    head(groups),
    O.fold(
      () => null,
      group => group
    )
  )

  const getOrderedGroups = (groups: readonly GroupSchema[]) => {
    if (firstGroup) {
      return isOdmonVariant
        ? createUpdatedGroups(orderProducts(firstGroup))
        : createUpdatedGroups(hideOlympusCamera(firstGroup))
    } else {
      return groups
    }
  }

  const updatedGroups = locale === 'en-US' ? getOrderedGroups(groups) : groups

  const selectedGroup = updatedGroups[selected.index]
  const isSelectedGroup = selected.showProducts && !!selectedGroup

  const skus: readonly string[] =
    groups.flatMap(group =>
      group.products.flatMap(product => [
        product?.sku?.toString(),
        ...(product.variant?.map(variant => variant?.sku) || [])
      ])
    ) || []

  useEffect(() => {
    const element = document.getElementById('productCards')
    element &&
      groups.length > 1 &&
      window.scrollTo({
        behavior: 'smooth',
        top: element.offsetTop
      })
  }, [selected, groups])

  const isDiscountActivated = !!(
    selectedGroup?.internalTitle?.includes('SS3') &&
    groups.length === 1 &&
    location.pathname.includes('/ss3')
  )

  // Force wipe Draft Cart state when leaving alarm sensors page.
  // TODO: This functionality can be removed when Jotai Providers are
  // template scoped: https://simplisafe.atlassian.net/browse/ECP-8723
  const wipeDraftCart = useResetDraftCart()
  useEffect(() => {
    return () => {
      wipeDraftCart()
    }
  }, [wipeDraftCart])

  const headerData = useHeaderRedesignQuery()
  const promoBannerExperiment = usePromoBannerExperimentQuery()

  return (
    <TrackingProvider metaTitle={metaTitle}>
      <PageToaster />
      <ApplyPromoCode />
      <SEO
        canonicalLink={canonicalLink || ''}
        isNofollow={isNofollow}
        isNoindex={isNoindex}
        lang={locale}
        metaDescription={metaDescription.metaDescription}
        metaKeywords={metaKeywords || []}
        metaTitle={metaTitle}
      />
      <PriceProvider locale={locale} skus={skus}>
        <PageWrapper>
          <PromoBannerWrapper
            experimentData={promoBannerExperiment}
            type={bannerStyle}
          />
          <Header {...headerData} />
          {breadcrumbTitle ? (
            <Breadcrumbs
              steps={[
                {
                  label: breadcrumbTitle,
                  slug
                }
              ]}
            />
          ) : null}
          <div className="max-w-8xl mx-auto mb-16 flex flex-col p-4 md:p-8">
            {locale === 'en-GB' && (
              <TrustpilotUKTopBanner className="mt-3 md:mb-4 md:mt-0"></TrustpilotUKTopBanner>
            )}
            {groups.length > 1 && (
              <AlarmTile
                groups={groups}
                onSelect={onSelect}
                selected={selected}
              />
            )}
            {isSelectedGroup ? <div id="productCards"></div> : null}
            {isSelectedGroup && selectedGroup.bannerType === BANNER_TYPE ? (
              <Banner />
            ) : null}
            {isDiscountActivated ? (
              <AuthenticationComponent
                language={locale}
                type={AuthenticationType.interactive}
              >
                <DiscountBanner />
              </AuthenticationComponent>
            ) : null}
            {isSelectedGroup ? <ProductCards group={selectedGroup} /> : null}
          </div>
          {footer ? (
            <Footer {...footer} type={locale === 'en-GB' ? 'Full' : 'Slim'} />
          ) : null}
          {liveChat ? <LiveChat /> : null}
        </PageWrapper>
      </PriceProvider>
    </TrackingProvider>
  )
}

export default function AlarmSensorTemplate(props: Props) {
  return (
    <Suspense>
      <Content {...props} />
    </Suspense>
  )
}

export const alarmSensorTemplateQuery = graphql`
  #graphql
  query AlarmSensorTemplate($id: String) {
    contentfulAlarmSensorPage(id: { eq: $id }) {
      ...alarmSensorPage
    }
  }
`
