import { parseContentfulJson } from '@ecomm/micro-copy'
import { GatsbyImage } from '@ecomm/shared-components'
import { graphql, useStaticQuery } from 'gatsby'
import { useState } from 'react'

import type { ProductCardFragment } from '../../components/ProductCard/schema'
import { ProSetup } from '../../components/ProSetup'
import { Modal } from './Modal'
import { proSetupSchema } from './schema'

type ProSetupProps = {
  readonly proSetup: ProductCardFragment
}

export function ProSetupHighlighted({ proSetup }: ProSetupProps) {
  const [isProductModalOpen, setIsProductModalOpen] = useState(false)
  const onProductModalOpenClose = (open: boolean) => setIsProductModalOpen(open)

  const data = proSetupSchema.parse(
    useStaticQuery(graphql`
      query proSetupData {
        contentfulAsset(contentful_id: { eq: "Ojp4sriAMWTTmkPLXcTCi" }) {
          gatsbyImageData(placeholder: BLURRED)
          description
        }
        contentfulJson(contentful_id: { eq: "2beg4nVstgPYQeM7cYsQaS" }) {
          contentful_id
          content {
            internal {
              content
            }
          }
        }
      }
    `)
  )

  const copy = parseContentfulJson(data, 'contentfulJson')

  return (
    <>
      <div className="bg-neutral-light-50 flex rounded-2xl p-5">
        <div className="basis-1/5">
          <GatsbyImage
            className="w-24 rounded-md"
            image={{
              gatsbyImageData: data.contentfulAsset.gatsbyImageData,
              title: data.contentfulAsset.description
            }}
          />
        </div>
        <div className="basis-4/5 pl-3 lg:pl-0">
          <h2 className="my-0 text-lg">{copy.get('title')}</h2>
          <div className="mt-2 text-sm opacity-95">
            {copy.get('description')}
            <br />
            <span>{copy.get('price_text')} </span>
            {
              <>
                <span
                  className="m-0 cursor-pointer underline hover:no-underline"
                  onClick={() => {
                    !isProductModalOpen && onProductModalOpenClose(true)
                  }}
                >
                  {copy.get('link')}
                </span>
                <Modal
                  open={isProductModalOpen}
                  setOpen={onProductModalOpenClose}
                />
              </>
            }
          </div>
          <ProSetup
            label={copy.get('label')}
            proSetup={proSetup}
            showPricingInfo={false}
          />
        </div>
      </div>
    </>
  )
}
